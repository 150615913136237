import React from "react"
import { Link } from "gatsby"

import usePolicies from "~/hooks/use-policies"

import Container from "~/components/container"
import PaymentIcons from "~/components/payment-icons"
import SocialNav from "~/components/social-nav"

const NavLink = ({ to, text }) => (
  <Link
    className="transition-all hover:text-orange-dark h-10 flex justify-center items-center"
    activeClassName="text-orange-dark font-medium"
    to={to}
  >
    {text}
  </Link>
)

const Footer = () => {
  const policies = usePolicies()

  return (
    <footer className="bg-cream mt-auto">
      <Container>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 md:gap-6">
          <div className="flex flex-col sm:flex-row justify-between lg:col-span-12 xl:col-span-10 xl:col-start-2">
            <div className="mt-auto mb-10 sm:mb-0">
              <SocialNav className="flex items-center justify-center sm:justify-start mb-4 md:-ml-2 space-x-2" />

              <div className="text-center sm:text-left space-y-1">
                <p className="flex items-center h-9">
                  Tue - Sat, 10:00 - 17:30
                </p>
                <p className="flex items-center">
                  <b>e:</b>{" "}
                  <a
                    className="text-orange hover:text-orange-dark ml-1 h-9 flex items-center"
                    title="HeartBee Flowers Contact Email"
                    href="mailto:hello@heartbeeflowers.com?subject=Hello"
                  >
                    hello@heartbeeflowers.com
                  </a>
                </p>

                <p className="flex items-center">
                  <b>t:</b>{" "}
                  <a
                    className="text-orange hover:text-orange-dark ml-1 h-9 flex items-center"
                    href="tel:01962621097"
                    title="HeartBee Flowers Phone number"
                  >
                    01962 621 097
                  </a>
                </p>
              </div>
            </div>

            <div className="flex items-start justify-around mt-auto">
              <div className="flex text-center flex-col justify-evenly md:mr-8">
                <NavLink to="/" text="Home" />
                <NavLink to="/about" text="About" />
                <NavLink to="/shop" text="Shop" />
                <NavLink to="/contact" text="Contact" />
              </div>

              <div className="flex text-center flex-col justify-evenly">
                {policies.length &&
                  policies.map(policy => (
                    <NavLink
                      key={policy.id}
                      to={`/policies/${policy.handle}`}
                      text={policy.pageTitle}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="lg:row-start-2 lg:col-span-12 xl:col-span-10 xl:col-start-2 flex flex-col-reverse md:flex-row justify-between items-center pt-4 md:pt-6 mt-4 md:mt-6 border-t-2 border-orange">
            <div className="mt-4 md:mt-0 text-gray-600">
              <span>© {new Date().getFullYear()} </span>
              <Link to="/">HeartBee Flowers</Link>
            </div>

            <PaymentIcons />
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
