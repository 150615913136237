import React, { useState, useEffect } from "react"
import { IoAdd, IoRemove } from "react-icons/io5"

const InputStepper = (props, { ...otherProps }) => {
  const [value, setValue] = useState(props.defaultValue)

  useEffect(() => {
    props.onChange(value)
  }, [props, value])

  const increase = () => {
    const { max } = props
    if (value === parseInt(max, 10)) return
    setValue(value + 1)
  }

  const decrease = () => {
    const { min } = props
    if (value === parseInt(min, 10)) return
    setValue(value - 1)
  }

  const handleChange = e => {
    const { onChange } = props
    if (onChange) onChange(e.target.value)
  }

  const handleBlur = e => {
    const { onBlur } = props
    if (onBlur) onBlur(e)
  }

  const onKeyDown = e => {
    switch (e.keyCode) {
      case 38: // KeyUp
        e.preventDefault()
        increase()
        break
      case 40: // KeyDown
        e.preventDefault()
        decrease()
        break
      default:
        break
    }
  }

  return (
    <div className="flex relative items-start">
      <button
        type="button"
        className="flex justify-center items-center h-12 w-14 transition-colors hover:text-orange mr-1"
        onClick={decrease}
      >
        <IoRemove className="h-6 w-6" />
      </button>

      <input
        {...otherProps}
        className="w-12 h-12 p-3 text-center rounded-md"
        type="text"
        onKeyDown={onKeyDown}
        onChange={handleChange}
        onBlur={handleBlur}
        autoComplete="off"
        value={value}
        readOnly
      />

      <button
        type="button"
        className="flex justify-center items-center h-12 w-14 transition-colors hover:text-orange ml-1"
        onClick={increase}
      >
        <IoAdd className="h-6 w-6" />
      </button>
    </div>
  )
}

export default InputStepper
