import React from "react"

import SectionTitle from "~/components/section-title"
import Container from "~/components/container"

const HomeEco = ({ data }) => {
  const { title, text } = data
  return (
    <section className="border-t-3 border-orange border-opacity-100 overflow-hidden">
      <Container>
        <div className="max-w-prose mx-auto">
          <SectionTitle
            title={title}
            Tag="h2"
            classes={["font-semibold py-4 px-8 text-center md:inline-flex"]}
          />

          <div
            className="prose sm:prose-sm md:prose-lg lg:prose-xl mx-auto mt-10"
            dangerouslySetInnerHTML={{
              __html: text.childMarkdownRemark.html,
            }}
          />
        </div>
      </Container>
    </section>
  )
}

export default HomeEco
