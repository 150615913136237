import React, { useContext, useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { useSpring, animated } from "react-spring"
import { IoCloseSharp } from "react-icons/io5"

import StoreContext from "~/context/store-context"
import { useToggleCartPanel } from "~/provider/context-provider"
import LineItem from "~/components/line-item"
import { getPrice, totalItems } from "~/utils"

const CartPanel = ({ location }) => {
  const { cart } = useContext(StoreContext)
  const myRef = useRef({ location: null })

  const { panelOpen, checkout } = cart
  const toggleCartPanel = useToggleCartPanel()

  const [cartQuantity, setCartQuantity] = useState(
    totalItems(checkout ? checkout.lineItems : [])
  )

  useEffect(() => {
    setCartQuantity(totalItems(checkout ? checkout.lineItems : []))
  }, [checkout])

  useEffect(() => {
    if (!myRef.current.location) myRef.current.location = location
    else if (myRef.current.location !== location) {
      if (panelOpen) {
        toggleCartPanel()
      }
      myRef.current.location = location
    }
  })

  const props = useSpring({
    transform: panelOpen ? "translateX(0px)" : "translateX(100%)",
    config: {
      tension: 210,
      friction: 20,
      clamp: true,
    },
  })

  function renderLineItems() {
    return (
      <div className="overflow-y-scroll">
        {checkout.lineItems.map(item => (
          <LineItem item={item} key={item.id.toString()} />
        ))}
      </div>
    )
  }

  function renderNoItems() {
    return (
      <div className="flex-1 flex flex-col justify-center items-center">
        <div className="flex flex-col items-center">
          <h4 className="text-2xl block mb-8">
            Your cart is empty
            <span className="ml-2" role="img" aria-label="CRYING FACE	">
              &#128546;
            </span>
          </h4>

          <Link
            className="bounce flex-1 text-center font-semibold bg-pink py-4 px-5 uppercase rounded-md"
            to="/shop"
          >
            Go to the Shop
          </Link>
        </div>
      </div>
    )
  }

  return (
    <animated.div
      className="hidden md:flex flex-col bg-white shadow-md fixed top-0 bottom-0 right-0 z-50 h-screen"
      style={{ width: "500px", ...props }}
    >
      <div className="flex border-b border-gray-200">
        <button
          onClick={toggleCartPanel}
          aria-label="Close Cart"
          className="bounce text-gray-600 h-16 w-16 flex justify-center items-center flex-none hover:text-orange"
        >
          <IoCloseSharp className="h-6 w-6" />
        </button>
        <h3 className="flex-1 flex justify-center items-center border-l border-gray-200 text-xl font-medium">
          Your Cart ({cartQuantity})
        </h3>
      </div>

      <div className="flex-1 flex flex-col overflow-y-auto">
        {checkout.lineItems.length > 0 && (
          <>
            {renderLineItems()}

            <div className="flex-1 flex-shrink-0 flex flex-col">
              <div className="p-4 flex justify-between border-b border-gray-200">
                <h4 className="flex items-center text-lg">
                  Total:
                  <span className="price ml-3 text-lg font-medium">
                    {getPrice(checkout.paymentDue)}
                  </span>
                </h4>

                <Link
                  className="text-center font-medium bg-pink py-4 px-6 uppercase rounded-md bounce"
                  to="/cart"
                >
                  Check Out
                </Link>
              </div>
            </div>
          </>
        )}

        {!checkout.lineItems.length && renderNoItems()}
      </div>
    </animated.div>
  )
}

export default CartPanel
