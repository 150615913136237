import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import useInstagram from "~/hooks/use-instagram"
import SectionTitle from "~/components/section-title"
import Container from "~/components/container"

const HomeInsta = () => {
  const posts = useInstagram()

  return (
    <section className="bg-pink">
      <Container>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-10">
          <SectionTitle
            title="Instagram"
            Tag="h2"
            classes={["font-semibold xl:col-span-3 xl:col-start-2 lg:block"]}
          />

          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4 row-start-2 xl:col-start-2 lg:col-span-12 xl:col-span-10">
            {posts.map(post => (
              <a
                href={post.permalink}
                key={post.id}
                target="_blank"
                rel="noopener noreferrer"
                className="instagram-block__post"
              >
                <GatsbyImage
                  image={post.image}
                  alt={post.caption}
                  className="rounded"
                />
              </a>
            ))}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default HomeInsta
