import React, { useContext } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import StoreContext from "~/context/store-context"
import Seo from "~/components/seo"
import CartAttributeForm from "~/components/cart-attribute-form"
import LineItem from "~/components/line-item"
import PageHero from "~/components/page-hero"

export default function Cart({ data: { coverImage, sketch } }) {
  const { cart } = useContext(StoreContext)
  const { checkout } = cart

  function renderNoItems() {
    return (
      <div className="flex-1 flex flex-col justify-center items-center col-span-full xl:col-start-2 xl:col-span-10">
        <div className="flex flex-col items-center">
          <p className="text-2xl block mb-8">
            Your cart is empty
            <span className="ml-2" role="img" aria-label="CRYING FACE	">
              &#128546;
            </span>
          </p>

          <Link
            className="text-center w-56 font-semibold bg-pink py-4 px-6 uppercase rounded"
            to="/shop"
          >
            Go to the Shop
          </Link>
        </div>
      </div>
    )
  }

  function renderLineItems() {
    return (
      <div className="flex flex-col cart-page__line-items space-y-4">
        {checkout.lineItems.map(item => (
          <LineItem item={item} key={item.id.toString()} />
        ))}
      </div>
    )
  }

  return (
    <>
      <Seo title="Your Cart" />
      <PageHero title="Your Cart" image={coverImage.gatsbyImageData} />

      <section>
        <div className="container mx-auto py-10 lg:py-20 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-12 gap-10">
            {checkout.lineItems.length > 0 && (
              <>
                <div className="xl:col-start-2 xl:col-span-6">
                  {renderLineItems()}
                </div>

                <div className="xl:col-span-4">
                  <div className="flex flex-col sticky top-4">
                    <CartAttributeForm checkout={checkout} />
                  </div>
                </div>
              </>
            )}

            {!checkout.lineItems.length && renderNoItems()}
          </div>

          <div className="flex items-center">
            <GatsbyImage
              image={sketch.gatsbyImageData}
              className="mt-10 mx-auto w-48 transform -rotate-45"
              alt="HeartBee Flowers · Winchester"
            />
          </div>
        </div>
      </section>
    </>
  )
}

export const query = graphql`
{
  coverImage: contentfulAsset(title: { eq: "Home Cover Image" }) {
    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
  }
  sketch: contentfulAsset(title: { eq: "lavender" }) {
    gatsbyImageData(
      width: 400
      layout: CONSTRAINED
      placeholder: TRACED_SVG
    )
  }
}
`
