import React from "react"

import useShopNav from "~/hooks/use-shop-nav"
import Container from "~/components/container"
import ShopMenu from "~/components/shop-menu"

const ShopLayout = ({ children, ...otherProps }) => {
  const navItems = useShopNav()

  return (
    <section {...otherProps}>
      <Container>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 sm:gap-6 lg:gap-10">
          <div className="lg:col-span-3 xl:col-start-2 xl:col-span-2">
            <ShopMenu menu={navItems} />
          </div>

          <div className="lg:col-span-full lg:col-start-4 xl:col-span-8">
            {children}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default ShopLayout
