import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import useSketches from "~/hooks/use-sketches"
import Container from "~/components/container"

const HomeAbout = ({ data }) => {
  const { acacia, euc, juniper } = useSketches()

  return (
    <section className="border-t-3 border-b-2 border-orange border-opacity-100 overflow-hidden">
      <Container>
        <div className="flex flex-col justify-center items-center">
          <div className="text-center max-w-screen-md prose sm:prose-sm md:prose-lg lg:prose-xl">
            <h2 className="mb-4 font-medium">{data.title}</h2>

            <div
              dangerouslySetInnerHTML={{
                __html: data.aboutText.childMarkdownRemark.html,
              }}
            />
          </div>

          <GatsbyImage
            image={data.beeImage.gatsbyImageData}
            className="my-10 md:mt-20 w-32"
            alt="HeartBee Flowers · Winchester"
          />
        </div>
      </Container>

      <div className="hidden md:grid grid-cols-2 w-full max-w-screen-2xl mx-auto">
        <div className="relative -mb-10">
          <GatsbyImage
            image={acacia}
            className="bottom-0 left-0 w-56"
            alt="HeartBee Flowers · Winchester"
            style={{ position: "absolute" }}
          />
          <GatsbyImage
            image={juniper}
            className="left-24 w-40 transform scale-50 -bottom-6"
            alt="HeartBee Flowers · Winchester"
            style={{ position: "absolute" }}
          />
        </div>

        <div className="relative -mb-11">
          <GatsbyImage
            image={euc}
            className="right-0 w-60 transform bottom-0"
            alt="HeartBee Flowers · Winchester"
            style={{ position: "absolute" }}
          />
        </div>
      </div>
    </section>
  )
}

export default HomeAbout
