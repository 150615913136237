import React, { useState, useContext, useEffect, useCallback } from "react"
import find from "lodash.find"

import Button from "~/components/button"
import SectionTitle from "~/components/section-title"
import InputNumber from "~/components/input-stepper"
import StoreContext from "~/context/store-context"
import { useAddItemToCart } from "~/provider/context-provider"
import { getPrice } from "~/utils"

const ProductForm = ({ product, ...otherProps }) => {
  const addItemToCart = useAddItemToCart()

  const {
    cart: { client },
  } = useContext(StoreContext)

  const {
    variants,
    variants: [initialVariant],
  } = product

  let options = product.options.filter(option => option.name !== "Title")

  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = useState(productVariant.availableForSale)

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result = fetchedProduct.variants.filter(
          variant => variant.id === productVariant.storefrontId
        )

        if (result.length > 0) setAvailable(result[0].available)
      })
    },
    [client.product, productVariant.storefrontId]
  )

  useEffect(() => {
    checkAvailability(product.storefrontId)

    if (
      typeof window !== "undefined" &&
      process.env.NODE_ENV === "production"
    ) {
      window.gtag("event", "view_item", {
        currency: "GBP",
        items: [
          {
            item_id: product.id,
            item_name: product.title,
            item_category: product.productType,
            item_variant: productVariant.title,
            price: parseFloat(productVariant.price),
            currency: "GBP",
            quantity: 1,
          },
        ],
        value: parseFloat(productVariant.price),
      })
    }
  }, [productVariant, checkAvailability, product])

  const handleOptionChange = event => {
    const { value } = event.target

    const selectedVariant = find(
      variants,
      ({ selectedOptions }) => selectedOptions[0].value === value
    )

    setVariant({ ...selectedVariant })
  }

  const handleAddToCart = () => {
    if (
      typeof window !== "undefined" &&
      process.env.NODE_ENV === "production"
    ) {
      window.gtag("event", "add_to_cart", {
        currency: "GBP",
        items: [
          {
            item_id: product.id,
            item_name: product.title,
            item_category: product.productType,
            item_variant: productVariant.title,
            price: parseFloat(productVariant.price),
            currency: "GBP",
            quantity: 1,
          },
        ],
        value: parseFloat(productVariant.price),
      })
    }

    addItemToCart(productVariant.storefrontId, quantity)
  }

  const optionSelected = value => {
    return variant.selectedOptions.map(o => o.value).includes(value)
  }

  const renderRadioBtn = (value, name) => {
    return (
      <div className="p-1 flex-shrink-0" key={`${name}-${value}`}>
        <label className="radio-btn w-full">
          <input
            name={name}
            type="radio"
            className="radio-btn__radio"
            checked={optionSelected(value)}
            onChange={handleOptionChange}
            value={value}
          />
          <span className="radio-btn__text h-12 w-full inline-block py-3 px-5 text-sm rounded hover:border-pink">
            {value}
          </span>
        </label>
      </div>
    )
  }

  const renderVariantRow = ({ id, name, values }) => {
    return (
      <div
        className="flex flex-col items-stretch lg:items-start lg:flex-row space-y-2 lg:space-y-0 flex-wrap prose"
        key={id}
      >
        <label
          className="flex items-center font-semibold py-3 pr-2 lg:mr-10"
          htmlFor={name}
        >
          {name}
        </label>

        <div className={`flex flex-wrap items-center flex-1`}>
          {values.map(value => renderRadioBtn(value, name))}
        </div>
      </div>
    )
  }

  return (
    <div {...otherProps}>
      <div className="">
        <SectionTitle
          title={product.title}
          classes={["hidden py-4 px-6 lg:inline-flex mb-10"]}
        />
        <h4 className=" text-2xl font-semibold">
          {getPrice(productVariant.price)}
        </h4>
      </div>

      <span className="md:-ml-14 border-pink border-t-2 block mt-4 mb-8" />

      <div
        className="product-details__description prose lg:prose-lg sm:hidden md:block lg:block"
        dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
      />

      <div className="product-form lg:mt-10 space-y-8 md:space-y-10">
        {options.map(renderVariantRow)}

        <div className="flex prose">
          <label
            className="flex items-center font-semibold py-3 pr-2"
            htmlFor="quantity"
          >
            Quantity
          </label>

          <InputNumber
            id="quantity"
            defaultValue={quantity}
            onChange={setQuantity}
            min="1"
          />
        </div>

        <div className="flex flex-col md:flex-row md:items-center md:space-x-6">
          <Button
            disabled={!available}
            classes=""
            onClick={handleAddToCart}
            type="submit"
          >
            Add to Cart
          </Button>

          {!available && (
            <p className="py-4" style={{ marginTop: "0", marginBottom: "0" }}>
              Unfortunately this option is sold out!
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductForm
