import React from "react"

import Seo from "~/components/seo"
import HomeHero from "~/components/sections/home-hero"
import HomeAbout from "~/components/sections/home-about"
import HomeShop from "~/components/sections/home-shop"
import HomeInsta from "~/components/sections/home-insta"
import HomeEco from "~/components/sections/home-eco"
import HomeOpeningTimes from "~/components/sections/home-opening-times"
import HomeFeatured from "~/components/sections/home-featured"

import useHomePage from "~/hooks/use-home-page"

export default function Home() {
  const {
    hero,
    aboutSection,
    featuredSection,
    shopSection,
    sustainabilitySection,
    openingTimes,
  } = useHomePage()

  return (
    <>
      <Seo />
      <HomeHero data={hero} />
      <HomeAbout data={aboutSection} />
      {featuredSection.active && <HomeFeatured data={featuredSection} />}
      <HomeShop data={shopSection} />
      <HomeOpeningTimes data={openingTimes} />
      <HomeEco data={sustainabilitySection} />
      <HomeInsta />
    </>
  )
}
