import React from "react"
import "@fontsource/courier-prime"
import "@fontsource/montserrat"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "./src/styles/global.css"
import "./src/styles/datepicker.scss"
import SimpleReactLightbox from "simple-react-lightbox"

export const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>{element}</SimpleReactLightbox>
)
