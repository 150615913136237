import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import Container from "~/components/container"

const HomeOpeningTimes = ({ data }) => {
  const { heading, cover, times } = data

  return (
    <section className="bg-orange">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-10">
          <div className="xl:col-start-3 lg:col-span-6 xl:col-span-4">
            <div className="h-60 sm:mx-20 md:mx-0 md:h-80 lg:h-80 md:ml-10 lg:ml-0 overflow-hidden rounded">
              <GatsbyImage
                className=" h-full w-full rounded"
                image={cover.gatsbyImageData}
                alt="HeartBee Flowers · Winchester"
              />
            </div>
          </div>

          <div className="lg:col-span-5 xl:col-span-4 lg:pl-10 flex flex-col justify-center">
            <h2 className="font-title text-center md:text-left text-white leading-8 font-semibold text-3xl md:text-4xl">
              {heading}
            </h2>

            <div className="md:pl-1 mt-8">
              <dl>
                {times.map(({ id, dayOfWeek, time, isClosed }) => (
                  <div key={id} className="py-1 grid grid-cols-2 sm:gap-4">
                    <dt className="md:text-lg text-center md:text-left font-medium text-white">
                      {dayOfWeek}
                    </dt>
                    <dd
                      className={[
                        "mt-1 md:text-lg text-center md:text-left sm:mt-0",
                        isClosed ? "text-gray-300" : "text-white",
                      ].join(" ")}
                    >
                      {time}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default HomeOpeningTimes
