import { graphql, useStaticQuery } from "gatsby"

const useInstagram = () => {
  const data = useStaticQuery(graphql`
    {
      allInstagramPost(limit: 12, sort: { order: DESC, fields: timestamp }) {
        edges {
          node {
            id
            caption
            permalink
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 60, layout: FULL_WIDTH, aspectRatio: 1)
              }
            }
          }
        }
      }
    }
  `)

  const posts = data.allInstagramPost.edges.map(({ node }) => {
    if (!node.localFile) return false

    const post = {
      image: node.localFile.childImageSharp.gatsbyImageData,
      id: node.id,
      caption: node.caption,
      permalink: node.permalink,
    }

    return post
  })

  return posts.filter(Boolean)
}

export default useInstagram
