import fetch from "isomorphic-fetch"
import React, { useContext, useReducer, useEffect } from "react"
import Client from "shopify-buy"

import Context from "~/context/store-context"
import { cartReducer } from "~/reducers/cart-reducer"

const SHOPIFY_CHECKOUT_STORAGE_KEY = "shopify_checkout_id"

const client = Client.buildClient(
  {
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
  },
  fetch
)

const createNewCheckout = async () => {
  const checkout = await client.checkout.create()
  return checkout
}

const fetchCheckout = id => client.checkout.fetch(id)

let initialCartState = {
  client,
  isAdding: false,
  checkout: { lineItems: [] },
  products: [],
  shop: {},
  panelOpen: false,
}

const ContextProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, initialCartState)

  useEffect(() => {
    const initializeCheckout = async () => {
      const isBrowser = typeof window !== "undefined"

      let existingCheckoutID = isBrowser
        ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
        : null

      if (navigator.userAgent.includes("Instagram")) {
        localStorage.setItem("shopify_checkout_id", null)
        existingCheckoutID = null
      }

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID)

          if (checkout && !checkout.completedAt) {
            console.log("Updating checkout in browser")
            dispatch({ type: "UPDATE_CHECKOUT", checkout })
          }

          return {
            client,
            checkout,
            isAdding: false,
            panelOpen: false,
          }
        } catch (e) {
          localStorage.setItem("shopify_checkout_id", null)
        }
      }

      const newCheckout = await createNewCheckout()
      localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, newCheckout.id)

      return {
        client,
        newCheckout,
        isAdding: false,
        panelOpen: false,
      }
    }

    initializeCheckout()
  }, [cart.client.checkout])

  return (
    <Context.Provider
      value={{
        cart,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export function useToggleCartPanel() {
  const { cart, dispatch } = useContext(Context)

  function togglePanel() {
    dispatch({ type: "TOGGLE_CART_PANEL", panelOpen: !cart.panelOpen })
  }

  return togglePanel
}

export function useAddCartAttribute() {
  const { dispatch } = useContext(Context)

  async function addCartAttribute(input) {
    const isBrowser = typeof window !== "undefined"
    const checkoutId = isBrowser
      ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
      : null

    const newCheckout = await client.checkout.updateAttributes(
      checkoutId,
      input
    )

    dispatch({ type: "UPDATE_CHECKOUT", checkout: newCheckout })
    return newCheckout
  }

  return addCartAttribute
}

export function useAddItemToCart() {
  const { cart, dispatch } = useContext(Context)

  async function addItemToCart(variantId, quantity) {
    if (variantId === "" || !quantity) {
      console.error("Both a size and quantity are required.")
      return
    }

    const isBrowser = typeof window !== "undefined"
    const checkoutId = isBrowser
      ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
      : null

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }]

    const newCheckout = await client.checkout.addLineItems(
      checkoutId,
      lineItemsToAdd
    )

    dispatch({ type: "UPDATE_CHECKOUT", checkout: newCheckout })
    dispatch({ type: "TOGGLE_CART_PANEL", panelOpen: !cart.panelOpen })
  }

  return addItemToCart
}

export function useRemoveLineItems() {
  const { dispatch } = useContext(Context)

  async function removeLineItems(checkoutId, itemIds) {
    const newCheckout = await client.checkout.removeLineItems(
      checkoutId,
      itemIds
    )

    dispatch({ type: "UPDATE_CHECKOUT", checkout: newCheckout })
  }

  return removeLineItems
}

// const updateLineItem = (client, checkoutID, lineItemID, quantity) => {
// const lineItemsToUpdate = [
//   { id: lineItemID, quantity: parseInt(quantity, 10) },
// ]
// return client.checkout
//   .updateLineItems(checkoutID, lineItemsToUpdate)
//   .then(res => {
//     updateStore(prevState => {
//       return { ...prevState, checkout: res }
//     })
//   })
// }

export default ContextProvider
