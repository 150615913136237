import { graphql, useStaticQuery } from "gatsby"

const useShopNav = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulComponentShopNav {
        navItems {
          text
          to
        }
      }
    }
  `)

  return data.contentfulComponentShopNav.navItems
}

export default useShopNav
