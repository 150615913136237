import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import GoogleMapReact from "google-map-react"

const mapOptions = require("~/map-options.json")

const isClient = typeof window !== "undefined"

const BeeMarker = ({ img }) => (
  <GatsbyImage
    image={img}
    className="w-6"
    alt="HeartBee Flowers · Winchester"
  />
)

const GoogleMap = ({ markerImg, classes }) => {
  const defaultMapSettings = {
    center: {
      lat: 51.06186401024897,
      lng: -1.310322134456896,
    },
    zoom: 13,
  }

  const createMapOptions = maps => {
    return {
      styles: mapOptions,
    }
  }

  return (
    <div className={classes} style={{ maxHeight: "500px" }}>
      {isClient && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.GATSBY_GOOGLE_API_KEY,
          }}
          defaultCenter={defaultMapSettings.center}
          defaultZoom={defaultMapSettings.zoom}
          options={createMapOptions}
        >
          <BeeMarker
            lat={51.0510924754567}
            lng={-1.3463892761368472}
            img={markerImg}
          />
        </GoogleMapReact>
      )}
    </div>
  )
}

export default GoogleMap
