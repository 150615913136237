import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const ProductGalleryThumbs = ({
  images = [],
  activeIndex = 0,
  productTitle,
  onClick,
  ...otherProps
}) => {
  return (
    <div {...otherProps}>
      {images.map(({ localFile, id }, i) => (
        <a
          key={id}
          href={localFile.publicURL}
          className={`${
            activeIndex === i ? "ring ring-red-300" : ""
          } rounded cursor-pointer`}
          onClick={e => {
            e.preventDefault()
            onClick(i)
          }}
        >
          <GatsbyImage
            image={localFile.childImageSharp.gatsbyImageData}
            className="rounded"
            objectPosition="50% 0%"
            alt={productTitle}
          />
        </a>
      ))}
    </div>
  )
}

export default ProductGalleryThumbs
