import { graphql, useStaticQuery } from "gatsby"

const useHomePage = () => {
  const data = useStaticQuery(graphql`
    {
      homePage: contentfulPageHomePage {
        hero {
          coverImage {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
          logo {
            gatsbyImageData(layout: FIXED, width: 208, placeholder: TRACED_SVG)
          }
          siteNav {
            navItems {
              text
              to
            }
          }
        }
        aboutSection {
          title
          aboutText {
            childMarkdownRemark {
              html
            }
          }
          beeImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
        featuredSection {
          active
          backgroundColour
          heading
          subTitle
          image {
            gatsbyImageData(quality: 90, layout: FULL_WIDTH)
          }
          button {
            text
            to
          }
        }
        shopSection {
          title
          shopLinks {
            link {
              text
              to
            }
            image {
              gatsbyImageData(quality: 100, width: 700, layout: CONSTRAINED)
            }
          }
        }
        sustainabilitySection {
          title
          text {
            childMarkdownRemark {
              html
            }
          }
        }
        openingTimes {
          heading
          cover {
            gatsbyImageData(quality: 70, layout: FULL_WIDTH)
          }
          times {
            id
            dayOfWeek
            time
            isClosed
          }
        }
      }
    }
  `)

  return data.homePage
}

export default useHomePage
